import React, { useContext } from 'react'
import Modal from '../../components/Modal';
import { mock_tournamnets } from '../../utils/mock_data';
import CreateTournamentForm from './CreateTournament';
import './index.css';
import SuccessImage from '../../assets/successfully-done.png';
import { Web3Context } from "../../contexts/Web3.context";
import { ethers } from "ethers";
import { createTournament } from '../../services/TournamentService';
import { useNavigate } from "react-router-dom";

const modalStyles = {
    content: {
        height: '90%',
        top: '47%',
        left: '82%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor:"black",
        border:"0px"
    },
};

const successModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "0px",
        color:"white",
        backgroundColor: "#0D0D0D",
        border: "1px solid #272727",
        borderRadius: "20px",
        padding:"10px"
    },
};

const viewModalStyles = {
    content: {
        width:"35%",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "0px",
        color:"white",
        backgroundColor: "#0D0D0D",
        border: "1px solid #272727",
        borderRadius: "20px",
        padding:"10px"
    },
};


function SuccessModalBody() {

    return (
        <div style={{display:"flex", flexDirection:"column"}}>
            <div style={{display:"flex", borderBottom: "1px solid #272727", padding:"0.5em"}}>
                New Tournament
            </div>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1em"}}>
                <div style={{width:"200px",height:"200px"}}>
                    <img style={{width:"100%",height:"100%",objectFit:"cover"}} src={SuccessImage}/>
                </div>
                <span>Great!</span>
                <p className='success_body_p' style={{maxWidth:"55%"}}>
                    New tournament has been created sucessfully.
                    You may able to view it in “My Tournament” page.
                </p>
            </div>
        </div>
    )
}


function ViewMatchBody({ match: { id, name, players, status } }) {
    return (
        <div style={{display:"flex", flexDirection:"column",gap:"2em"}}>
            <div style={{display:"flex", borderBottom: "1px solid #272727", padding:"0.5em"}}>
               Tournament Details
            </div>
            <div className='viewMatchDetails' style={{display:"flex", flexDirection:"column", gap:"1em"}}>
                <div style={{ display: "flex", gap: "1em" }}>
                    <span className='viewMatchDetailHeading'>ID:</span>
                    <span>{id}</span>
                </div>
                <div style={{ display: "flex", gap: "1em" }}>
                    <span className='viewMatchDetailHeading'>Name:</span>
                    <span>{name}</span>
                </div>
                <div style={{ display: "flex", gap: "1em" }}>
                    <span className='viewMatchDetailHeading'>Players:</span>
                    <span>{players}</span>
                </div>
                <div style={{ display: "flex", gap: "1em" }}>
                    <span className='viewMatchDetailHeading'>Status:</span>
                    <span>{status}</span>
                </div>
            </div>
            <button className="btn btn-primary join_tournament_btn joinMatchBtn">
                    <span>Join and Play</span>
                </button>
        </div>
    );
}



export default function Index() {
    const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false);
    const [joinModalIsOpen, setJoinModalIsOpen] = React.useState(false);
    const [successModalIsOpen, setSuccessModalIsOpen] = React.useState(false);
    const [viewMatchModalIsOpen, setviewMatchModalIsOpen] = React.useState(false);
    const [selectedMatch, setSelectedMatch] = React.useState(-1);
    const { network, config } = useContext(Web3Context);
    const navigate = useNavigate();

    const handleHostButtonClick = () => {
        setCreateModalIsOpen(true);
    }

    const handleJoinButtonClick = () => {
        setJoinModalIsOpen(true);
    }

    const onSubmit = async (player1, player2, player3, player4, player5, player6, player7, player8) => {

        const playerArray = [player1, player2, player3, player4];
        console.log("playerArray: ", playerArray);

        //todo 
        const tx = await config.matchMakerContractWSigner.createDemoTournament(playerArray);
        const reciept = await tx.wait();

        console.log(reciept);

        window.location.href = "/#/tournament/1/"+player1+"/"+ player2+"/"+ player3+"/"+ player4+"/"+player5+"/"+player6+"/"+player7+"/"+player8; 
    }

    const onViewMatch = (index) => {
        setviewMatchModalIsOpen(true);
        setSelectedMatch(index);
    }

    return (
        <div className='home_page_main'>
            <div className='home_page_top'>
                <h3>List of Tournaments</h3>
                <button onClick={handleHostButtonClick} className="btn btn-primary join_tournament_btn">
                    <span>Host a Tournament</span>
                    <i class="fa-solid fa-plus"></i>
                </button>
            </div>
            <div style={{ color: "white" }} className='tournaments-table-container'>
                <table style={{ color: "white" }} class="table">
                    <thead>
                        <tr>
                            <td scope="col">#</td>
                            <td scope="col">Id</td>
                            <td scope="col">Name</td>
                            <td scope="col">Players</td>
                            <td scope="col">Status</td>
                            <td scope="col">Winner</td>
                            <td scope="col">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            mock_tournamnets.map((tournament, i) => (
                                <tr>
                                    <th scope="row">{i + 1}</th>
                                    <td>{tournament.id}</td>
                                    <td>{tournament.name}</td>
                                    <td>{tournament.players}</td>
                                    <td>{tournament.winner.slice(0,5)}</td>
                                    <td className='match_status'>
                                        {tournament.status}
                                    </td>
                                    <td className='view_btn' onClick={()=>{onViewMatch(i)}}>
                                        <span>View</span>  
                                        <i class="fa-solid fa-location-arrow"></i>
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>
            <Modal setIsOpen={setCreateModalIsOpen} style={modalStyles} modalIsOpen={createModalIsOpen} body={<CreateTournamentForm onSubmit={onSubmit}/>} />
            <Modal setIsOpen={setJoinModalIsOpen} modalIsOpen={joinModalIsOpen} body={<CreateTournamentForm />} />
            <Modal setIsOpen={setSuccessModalIsOpen} style={successModalStyles} modalIsOpen={successModalIsOpen} body={< SuccessModalBody />} />
            <Modal style={viewModalStyles} modalIsOpen={viewMatchModalIsOpen} setIsOpen={setviewMatchModalIsOpen}  body={< ViewMatchBody match={mock_tournamnets[selectedMatch]}/>} />
        </div>
    )
}
