import React, { useContext } from "react";
import { ethers } from "ethers";
import { useTranslation } from 'react-i18next';
import { Web3Context } from "../../contexts/Web3.context";
import useEvents from "../../hooks/useEvents";
import { getSlicedWalletAddress } from "../../utils/helpers";

function Referrals() {
  const events = useEvents({ eventName: "Referral" });

  const { accountAddress } = useContext(Web3Context);
  const { t } = useTranslation("referral_page");

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Share referral link",
          url: window.location.protocol + "//" + window.location.host + "/#/" + accountAddress,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      // fallback
    }
  };

  const handleCopy = () => {
    const url = window.location.protocol + "//" + window.location.host + "/#/" + accountAddress;
    navigator.clipboard.writeText(url);
    alert("Copied to clipboard");
  };

  return (
    <div className="casino__referrals">
      <div className="input-group mb-4" style={{ width: "90%" }}>
        <input
          value={accountAddress || ""}
          type="text"
          className="form-control rounded-md text-white"
          placeholder="Coin Price"
          aria-label="Coin Price"
          aria-describedby="basic-addon1"
          disabled
          style={{
            backgroundColor: "#E41717",
            border: "2px solid rgba(0, 0, 0, 0.4)",
            height: 48,
          }}
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={handleShare}
          style={{
            backgroundColor: "#E41717",
            border: "2px solid rgba(0, 0, 0, 0.4)",
            height: 48,
            color: "white",
          }}>
          <i className="fa fa-share" />
        </button>
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={handleCopy}
          style={{
            backgroundColor: "#E41717",
            border: "2px solid rgba(0, 0, 0, 0.4)",
            height: 48,
            color: "white",
          }}>
          <i className="fa fa-copy" />
        </button>
      </div>

      <div className="dropdown w-100">
        <button
          className="btn dropdown-toggle rounded-pill px-4 mx-auto text-white d-flex flex-row justify-content-between align-items-center"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ backgroundColor: "#E41717", width: "90%", border: "2px solid rgba(0, 0, 0, 0.4)", height: 48 }}>
          {t("referrals")}
        </button>
        <ul className="dropdown-menu"></ul>
      </div>

      <ul>
        {events.map((event, idx) => (
          <li key={`referral-item-${idx}`}>
            <span className="badge rounded-pill bg-white text-dark px-4 py-2">
              {getSlicedWalletAddress(event.depositor)}
            </span>
            <span>{event.firstLevelAmount ? ethers.utils.formatEther(String(event.firstLevelAmount)) : 0} ETH</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Referrals;
