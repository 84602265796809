import React, { useContext, useEffect, useState } from "react";
import { Web3Context } from "../contexts/Web3.context";
import { getEventsByAccountAddress } from "../services/events";

function useEvents({ eventName, limit }) {
  const { config, accountAddress } = useContext(Web3Context);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function getEventsFromContractRpc() {
      if (config?.slotMachineContractRpc) {
        const blockLimit =
          config.nodeProviderBlockLimit === 0 ? "latest" : config.fromBlock + config.nodeProviderBlockLimit - 1;

        const events = await getEventsByAccountAddress(
          config.slotMachineContractRpc,
          eventName,
          accountAddress,
          config.fromBlock,
          blockLimit,
          limit,
        );

        console.log(events);
        setEvents(events ? events : []);
      }
    }
    getEventsFromContractRpc();
    () => setEvents([]);
  }, [config, accountAddress]);

  return events;
}

export default useEvents;
