import React, { useContext } from "react";
import { SlotMachineContext } from "../../contexts/SlotMachine.context";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";
import useEvents from "../../hooks/useEvents";
import * as icon from "../../utils/slotIconsImportHelper";

const slotIconsMapByRand = {
  0: icon.slotIcon1,
  1: icon.slotIcon2,
  2: icon.slotIcon3,
  3: icon.slotIcon4,
  4: icon.slotIcon5,
  5: icon.slotIcon6,
  6: icon.slotIcon7,
  7: icon.slotIcon8,
  8: icon.slotIcon9,
  9: icon.slotIcon10,
  10: icon.slotIcon11,
  11: icon.slotIcon12,
  12: icon.slotIcon13,
  13: icon.slotIcon14,
  14: icon.slotIcon15,
};

function RewardHistory() {
  const events = useEvents({ eventName: "RollFulfilled" });
  const { t } = useTranslation("earnings_page");

  const { defaultToken } = useContext(SlotMachineContext);

  const getRandIcon = rand => {
    return <img src={slotIconsMapByRand[rand]} height="34" width="27" className="mx-1" />;
  };

  const renderIconsByRand = (rand1, rand2, rand3) => {
    return (
      <div className="badge rounded-pill bg-white">
        <span>{getRandIcon(String(rand1))}</span>
        <span>{getRandIcon(String(rand2))}</span>
        <span>{getRandIcon(String(rand3))}</span>
      </div>
    );
  };

  return (
    <div className="casino__history">
      <div className="dropdown w-100">
        <button
          className="btn dropdown-toggle rounded-pill px-4 mx-auto text-white d-flex flex-row justify-content-between align-items-center"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ backgroundColor: "#E41717", width: "90%", border: "2px solid rgba(0, 0, 0, 0.4)", height: 48 }}>
          {t("earnings_referrals")}
        </button>
        <ul className="dropdown-menu"></ul>
      </div>

      <ul>
        {events.map((event, idx) => (
          <li key={`rand-item-${idx}`}>
            <span>
              {renderIconsByRand(event?.vrfRequest?.rand1, event?.vrfRequest?.rand2, event?.vrfRequest?.rand3)}
            </span>
            <span>
              {ethers.utils.formatEther(String(event?.vrfRequest?.reward))}{" "}
              {event?.symbol !== "CDAPP" ? event.symbol : defaultToken.symbol}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RewardHistory;
