export const mock_tournamnets = [
    {
        id: 1,
        name: "nerds",
        players: 256,
        status: "IN_PROGRESS",
        winner: "0x936cDF78A3bC672fb08B1936Fc77aBb2e02F7f46"
    },
    {
        id: 1,
        name: "nerds",
        players: 256,
        status: "IN_PROGRESS",
        winner: "0x936cDF78A3bC672fb08B1936Fc77aBb2e02F7f46"
    }, {
        id: 1,
        name: "nerds",
        players: 256,
        status: "IN_PROGRESS",
        winner: "0x936cDF78A3bC672fb08B1936Fc77aBb2e02F7f46"
    }, {
        id: 1,
        name: "nerds",
        players: 256,
        status: "IN_PROGRESS",
        winner: "0x936cDF78A3bC672fb08B1936Fc77aBb2e02F7f46"
    },
];

export const mock_tournamnet_matches = [
    {
        id: 1,
        name: "nerds1",
        nextMatchId: 5,
        tournamentRoundText: "1",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "WON",
                "isWinner": true,
                "status": null,
                "name": "player1"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "LOST",
                "isWinner": false,
                "status": null,
                "name": "player2"
            }
        ]
    },
    {
        id: 2,
        name: "nerds2",
        nextMatchId: 5,
        tournamentRoundText: "1",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "WON",
                "isWinner": true,
                "status": null,
                "name": "player3"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "LOST",
                "isWinner": false,
                "status": null,
                "name": "player4"
            }
        ]
    },
    {
        id: 3,
        name: "nerds3",
        nextMatchId: 6,
        tournamentRoundText: "1",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "WON",
                "isWinner": true,
                "status": null,
                "name": "player5"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "LOST",
                "isWinner": false,
                "status": null,
                "name": "player6"
            }
        ]
    },
    {
        id: 4,
        name: "nerds4",
        nextMatchId: 6,
        tournamentRoundText: "1",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "WON",
                "isWinner": true,
                "status": null,
                "name": "player7"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "LOST",
                "isWinner": false,
                "status": null,
                "name": "player8"
            }
        ]
    },
    {
        id: 5,
        name: "nerds5",
        nextMatchId: 7,
        tournamentRoundText: "2",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "WON",
                "isWinner": true,
                "status": null,
                "name": "player1"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "LOST",
                "isWinner": false,
                "status": null,
                "name": "player3"
            }
        ]
    },
    {
        id: 6,
        name: "nerds6",
        nextMatchId: 7,
        tournamentRoundText: "2",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "WON",
                "isWinner": true,
                "status": null,
                "name": "player5"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "LOST",
                "isWinner": false,
                "status": null,
                "name": "player7"
            }
        ]
    },
    {
        id: 7,
        name: "nerds7",
        nextMatchId: null,
        tournamentRoundText: "2",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "WON",
                "isWinner": true,
                "status": null,
                "name": "player1"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "LOST",
                "isWinner": false,
                "status": null,
                "name": "player5"
            }
        ]
    },
];

export const mock_unstarted_tournamnet_matches = [
    {
        id: 1,
        name: "match1",
        nextMatchId: 5,
        tournamentRoundText: "1",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "TBD",
                "isWinner": true,
                "status": null,
                "name": "player1"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "TBD",
                "isWinner": false,
                "status": null,
                "name": "player2"
            }
        ]
    },
    {
        id: 2,
        name: "match2",
        nextMatchId: 5,
        tournamentRoundText: "1",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "TBD",
                "isWinner": true,
                "status": null,
                "name": "player3"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "TBD",
                "isWinner": false,
                "status": null,
                "name": "player4"
            }
        ]
    },
    {
        id: 3,
        name: "match3",
        nextMatchId: 6,
        tournamentRoundText: "1",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "TBD",
                "isWinner": true,
                "status": null,
                "name": "player5"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "TBD",
                "isWinner": false,
                "status": null,
                "name": "player6"
            }
        ]
    },
    {
        id: 4,
        name: "match4",
        nextMatchId: 6,
        tournamentRoundText: "1",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "TBD",
                "isWinner": true,
                "status": null,
                "name": "player7"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "TBD",
                "isWinner": false,
                "status": null,
                "name": "player8"
            }
        ]
    },
    {
        id: 5,
        name: "match5",
        nextMatchId: 7,
        tournamentRoundText: "2",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "TBD",
                "isWinner": true,
                "status": null,
                "name": "TBD"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "TBD",
                "isWinner": false,
                "status": null,
                "name": "TBD"
            }
        ]
    },
    {
        id: 6,
        name: "match6",
        nextMatchId: 7,
        tournamentRoundText: "2",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "TBD",
                "isWinner": true,
                "status": null,
                "name": "TBD"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "TBD",
                "isWinner": false,
                "status": null,
                "name": "TBD"
            }
        ]
    },
    {
        id: 7,
        name: "match7",
        nextMatchId: null,
        tournamentRoundText: "2",
        startTime: "2021-05-30",
        state: "IN_PROGRESS",
        participants: [
            {
                "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc",
                "resultText": "TBD",
                "isWinner": true,
                "status": null,
                "name": "TBD"
            },
            {
                "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
                "resultText": "TBD",
                "isWinner": false,
                "status": null,
                "name": "TBD"
            }
        ]
    },
];