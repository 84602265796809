import { ethers } from "ethers";
import { erc20Abi } from "../utils/commonAbis";

const sortedEvents = (events, limit) => {
  const sortedEvents = events.slice(limit ? -limit : -10).sort((a, b) => b.blockNumber - a.blockNumber);
  return sortedEvents;
};

export const getEventsByAccountAddress = async (contract, eventName, accountAddress, fromBlock, blockLimit, limit) => {
  try {
    if (eventName === "RollFulfilled") {
      return await getRollFulfilledEvents({ contract, accountAddress, fromBlock, blockLimit, limit });
    }

    if (eventName === "Referral") {
      const eventFilter = await contract.filters.Referral(accountAddress);
      const rpcEvents = await contract.queryFilter(eventFilter, fromBlock, blockLimit);

      const formatEvents = rpcEvents.map(
        ({
          blockNumber,
          transactionHash,
          args: { depositor, referrer, twoLevelReferrer, level, rollType, firstLevelAmount, secondLevelAmount },
        }) => {
          return {
            transactionHash,
            blockNumber,
            depositor,
            referrer,
            twoLevelReferrer,
            level,
            rollType,
            firstLevelAmount,
            secondLevelAmount,
          };
        },
      );
      return sortedEvents(formatEvents, limit);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getRollFulfilledEvents = async ({
  contract,
  provider,
  accountAddress,
  fromBlock,
  blockLimit,
  limit,
  winType,
}) => {
  try {
    const eventFilter = await contract.filters.RollFulfilled(null, accountAddress || null, winType || []);
    const rpcEvents = await contract.queryFilter(eventFilter, fromBlock, blockLimit);

    const formatEvents = await Promise.all(
      rpcEvents.map(
        async ({ blockNumber, transactionHash, args: { requestId, sender, winType, timestamp, vrfRequest } }) => {
          let symbol;
          if (provider) {
            const erc20Contract = new ethers.Contract(vrfRequest.tokenAddress, erc20Abi, provider);
            symbol = await erc20Contract.symbol();
          }
          return {
            transactionHash,
            blockNumber,
            requestId,
            sender,
            winType,
            timestamp,
            vrfRequest,
            symbol,
          };
        },
      ),
    );
    return sortedEvents(formatEvents, limit);
  } catch (error) {
    console.log(error);
  }
};
