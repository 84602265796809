import React from "react";
import { Web3ContextProvider } from "./Web3.context";
import { SlotMachineContextProvider } from "./SlotMachine.context";

// https://medium.com/front-end-weekly/how-to-combine-context-providers-for-cleaner-react-code-9ed24f20225e
export const combineComponents = (...components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>,
  );
};

const providers = [Web3ContextProvider, SlotMachineContextProvider];

export const AppContextProvider = combineComponents(...providers);
