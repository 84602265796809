import React, { useState } from 'react';
import "./index.css";

const modalStyles = {
    content: {
        top: '47%',
        left: '82%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor:"black",
        border:"0px"
    },
};

export default function Index({ onSubmit }) {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telegram, setTelegram] = useState("");
    const [participantsCount, setParticipantsCount] = useState(0);
    const [deadline, setDeadline] = useState("");
    const [tokenAddress, setTokenAddress] = useState("0x430599f75Bb7156406eDA956f59f494F10b46C5d");
    const [fee, setFee] = useState(0);

    /**
     * demo 4 players
     */
    const [player1, setPlayer1] = useState("");
    const [player2, setPlayer2] = useState("");
    const [player3, setPlayer3] = useState("");
    const [player4, setPlayer4] = useState("");

    const [player5, setPlayer5] = useState("");
    const [player6, setPlayer6] = useState("");
    const [player7, setPlayer7] = useState("");
    const [player8, setPlayer8] = useState("");

    const onNameChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setName(value);
    }
    
    const onemailChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setEmail(value);
    }

    const ontelegramChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setTelegram(value);
    }

    const onParticipantsCountChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setParticipantsCount(value);
    }

    const onDeadlineChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setDeadline(value);
    }

    const onTokenAddressChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setTokenAddress(value);
    }

    const onFeeChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setFee(value);
    }

    const _onSubmit = async (e) => {
        e.preventDefault();
        const tournament_data = {
            tournament_name: name,
            host_email: email,
            host_telegram: telegram,
            max_participants: participantsCount
        };
       await onSubmit(player1, player2, player3, player4, player5, player6, player7, player8);
    }

    const onPlayer1Change = async (e) => {
        const value = e.target.value;
        console.log(value);
        setPlayer1(value);
    }

    const onPlayer2Change = async (e) => {
        const value = e.target.value;
        console.log(value);
        setPlayer2(value);
    }
    const onPlayer3Change = async (e) => {
        const value = e.target.value;
        console.log(value);
        setPlayer3(value);
    }
    const onPlayer4Change = async (e) => {
        const value = e.target.value;
        console.log(value);
        setPlayer4(value);
    }

    const onPlayer5Change = async (e) => {
        const value = e.target.value;
        console.log(value);
        setPlayer5(value);
    }
    const onPlayer6Change = async (e) => {
        const value = e.target.value;
        console.log(value);
        setPlayer6(value);
    }
    const onPlayer7Change = async (e) => {
        const value = e.target.value;
        console.log(value);
        setPlayer7(value);
    }
    const onPlayer8Change = async (e) => {
        const value = e.target.value;
        console.log(value);
        setPlayer8(value);
    }

    return (
        <div className='create-tournament'>
            <div className='new_tournament_heading'>New Tournament</div>
            <form>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Name of Tournament</label>
                    <input value={name} onChange={onNameChange} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Host Email</label>
                    <input value={email} onChange={onemailChange} type="email" className="form-control" id="exampleInputPassword1" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Host Telegram</label>
                    <input value={telegram} onChange={ontelegramChange} type="text" className="form-control" id="exampleInputPassword1" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Max Participants</label>
                    <input value={participantsCount} onChange={onParticipantsCountChange}  type="number" min={0} className="form-control" id="exampleInputPassword1" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Deadline To Apply</label>
                    <input value={deadline} onChange={onDeadlineChange} type="date" className="form-control" id="exampleInputPassword1" />
                </div>
                <div class="mb-3">
                    <label for="player1" class="form-label">Player1</label>
                    <input value={player1} onChange={onPlayer1Change} type="text" className="form-control" id="player1" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Player2</label>
                    <input value={player2} onChange={onPlayer2Change} type="text" className="form-control" id="player2" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Player3</label>
                    <input value={player3} onChange={onPlayer3Change} type="text" className="form-control" id="player3" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Player4</label>
                    <input value={player4} onChange={onPlayer4Change} type="text" className="form-control" id="player4" />
                </div>

                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Player5</label>
                    <input value={player5} onChange={onPlayer5Change} type="text" className="form-control" id="player4" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Player6</label>
                    <input value={player6} onChange={onPlayer6Change} type="text" className="form-control" id="player4" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Player7</label>
                    <input value={player7} onChange={onPlayer7Change} type="text" className="form-control" id="player4" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Player8</label>
                    <input value={player8} onChange={onPlayer8Change} type="text" className="form-control" id="player4" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Select Token as Fee</label>
                    <select onChange={onTokenAddressChange} value={tokenAddress} className="form-select" aria-label="Default select example">
                        <option selected={true} value="0x430599f75Bb7156406eDA956f59f494F10b46C5d">ETH</option>
                        <option value="0x430599f75Bb7156406eDA956f59f494F10b46C5d">USDT</option>
                        <option value="0x430599f75Bb7156406eDA956f59f494F10b46C5d">PFAN</option>
                        <option value="0x430599f75Bb7156406eDA956f59f494F10b46C5d">TEST</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Fee (In Tokens)</label>
                    <input type="number" min={0} value={fee} onChange={onFeeChange} className="form-control" id="exampleInputPassword1" />
                </div>
                <button onClick={_onSubmit} style={{width:"100%"}} className="btn btn-primary join_tournament_btn">
                    <span>Submit</span>
                </button>
            </form>
        </div>
    )
}
