import { useState, useEffect } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";

export default function Settings() {
  //
  const [sound, setSound] = useState(true);
  const [depositSound, setDepositSound] = useState(true);
  const { t } = useTranslation("settings_page");

  useEffect(() => {
    const backgroundSoundSetting = localStorage.getItem("backgroundSoundSetting");
    if (backgroundSoundSetting && backgroundSoundSetting === "false") {
      setSound(false);
    }
  }, []);

  const onSoundChange = () => {
    let newSetting = "true";
    if (sound === true) {
      newSetting = "false";
    }
    localStorage.setItem("backgroundSoundSetting", newSetting);
    setSound(prev => {
      return !prev;
    });
  };

  const onDepositSoundChange = () => {
    setDepositSound(prev => {
      return !prev;
    });
  };

  return (
    <div className="setting-container">
      <div className="setting_main_heading">{t("title")}</div>
      <div className="toggles_container">
        <div className="toggle_setting_container">
          <div className="toggle_setting_left_container">
            <span className="setting_title">{t("toggle_one_title")}</span>
            <span className="setting_desc">{t("toggle_one_desc")}</span>
          </div>
          <div className="toggle_setting_right_container">
            <span>{sound ? "On" : "Off"}</span>
            <div>
              <label className="switch">
                <input type="checkbox" checked={sound} onChange={onSoundChange} />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>

        <div className="toggle_setting_container">
          <div className="toggle_setting_left_container">
            <span className="setting_title">{t("toggle_two_title")}</span>
            <span className="setting_desc">{t("toggle_two_desc")}</span>
          </div>
          <div className="toggle_setting_right_container">
            <span>{depositSound ? `${t("on")}` : `${t("off")}`}</span>
            <div>
              <label className="switch">
                <input type="checkbox" checked={depositSound} onChange={onDepositSoundChange} />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
