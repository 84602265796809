import React from 'react';
import "./index.css";
import UserAvatar from "../../assets/user_avatar.png";


export default function UserProfilePage() {
    return (
        <div className='user_profile_page'>
            <h3>My Profile</h3>
            <div className='user-details'>
                <span>Profile Photo</span>
                <div className='user_profile'>
                    <div className='img-container'>
                        <img src={UserAvatar} />
                    </div>
                    <div className='profile_img_upload_size'>
                        <span className='action-color'>Upload Profile Photo</span>
                        <span className='rec_size'>Recommended Size: 100px to 300px</span>
                    </div>
                </div>
                <div className='input_fields'>
                    <div className='field'>
                        <span>Username</span>
                        <input placeholder='username here....' />
                        <span className='action-color'>Change Username</span>
                    </div>
                    <div className='field'>
                        <span>Username</span>
                        <input placeholder='username here....' />
                        <span className='action-color'>Change Username</span>
                    </div>
                    <div className='field'>
                        <span>Username</span>
                        <input placeholder='username here....' />
                        <span className='action-color'>Change Username</span>
                    </div>
                    <div className='field'>
                        <span>Username</span>
                        <input placeholder='username here....' />
                        <span className='action-color'>Change Username</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
