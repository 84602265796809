import React, { createContext, useState, useEffect } from "react";
import { network as networkConfig } from "../config";
import { ethers } from "ethers";
import SlotMachine from "../abis/SlotMachine.json";
import MatchMaker from "../abis/MatchMaker.json";

export const Web3Context = createContext(null);

export const Web3ContextProvider = ({ children }) => {
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);
  const [jsonRpcProvider, setJsonRpcProvider] = useState(null);
  const [accountAddress, setAccountAddress] = useState(null);
  const [network, setNetwork] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    if (window.ethereum) {
      connectWeb3();
      window.ethereum.on("chainChanged", () => {
        connectWeb3();
      });

      window.ethereum.on("accountsChanged", () => {
        connectWeb3();
      });
    }

    return () => {
      setProvider(null);
      setJsonRpcProvider(null);
      setNetwork(null);
      setChainId(null);
      setSigner(null);
      setAccountAddress(null);
      setConfig(null);
    };
  }, []);

  const getSlotMachineContracts = (provider, rpcProvider, name, signer) => {
    let slotMachineContract;
    let slotMachineContractRpc;
    let slotMachineContractWSigner;

    if (networkConfig[name]?.slotMachineContractAddress) {
      slotMachineContract = new ethers.Contract(
        networkConfig[name].slotMachineContractAddress,
        SlotMachine.abi,
        provider,
      );

      slotMachineContractRpc = new ethers.Contract(
        networkConfig[name].slotMachineContractAddress,
        SlotMachine.abi,
        rpcProvider,
      );

      slotMachineContractWSigner = slotMachineContract.connect(signer);
    }

    return [slotMachineContract, slotMachineContractRpc, slotMachineContractWSigner];
  };

  const getMatchMakerContract = (provider, rpcProvider, name, signer) => {
    let matchMakerContract;
    let matchMakerContractRpc;
    let matchMakerContractWSigner;

    if (networkConfig[name]?.matchMakerContractAddress) {
      matchMakerContract = new ethers.Contract(
        networkConfig[name].matchMakerContractAddress,
        MatchMaker.abi,
        provider,
      );

      matchMakerContractRpc = new ethers.Contract(
        networkConfig[name].matchMakerContractAddress,
        MatchMaker.abi,
        rpcProvider,
      );

      matchMakerContractWSigner = matchMakerContract.connect(signer);
    }

    return [matchMakerContract, matchMakerContractRpc, matchMakerContractWSigner];
  };

  const connectWeb3 = async () => {
    try {
      const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
      let { chainId, name } = await ethProvider.getNetwork();
      console.log("getNetWorkname :", name);
      if(chainId ==5777) {
        name = "ganache";
      }
      const rpcProvider = new ethers.providers.JsonRpcProvider(networkConfig[name].rpc);

      await ethProvider.send("eth_requestAccounts", []);
      const ethSigner = ethProvider.getSigner();
      const account = await ethSigner.getAddress();

      const [slotMachineContract, slotMachineContractRpc, slotMachineContractWSigner] = getSlotMachineContracts(
        ethProvider,
        rpcProvider,
        name,
        ethSigner,
      );

      const [matchMakerContract, matchMakerContractRpc, matchMakerContractWSigner] = getMatchMakerContract(
        ethProvider,
        rpcProvider,
        name,
        ethSigner,
      );


      const config = {
        ...networkConfig[name],
        slotMachineContract,
        slotMachineContractRpc,
        slotMachineContractWSigner,
        matchMakerContract,
        matchMakerContractRpc,
        matchMakerContractWSigner
      };

      setProvider(ethProvider);
      setJsonRpcProvider(rpcProvider);
      setNetwork(name);
      setChainId(chainId);
      setSigner(ethSigner);
      setAccountAddress(account);
      setConfig(config);
    } catch (error) {
      console.log(error);
    }
  };

  const checkOrAddNetworkChain = network => {
    if (provider) {
      if (networkConfig[network].metamaskChainConfig?.rpcUrls) {
        provider.send("wallet_addEthereumChain", [networkConfig[network].metamaskChainConfig]).catch(error => {
          console.log(error);
        });
      } else {
        provider.send("wallet_switchEthereumChain", [networkConfig[network].metamaskChainConfig]).catch(error => {
          console.log(error);
        });
      }

      connectWeb3();
    }
  };

  return (
    <Web3Context.Provider
      value={{ signer, provider, jsonRpcProvider, accountAddress, network, chainId, config, checkOrAddNetworkChain }}>
      {children}
    </Web3Context.Provider>
  );
};
