import BNBIcon from "./assets/bnb.png";
import ETHIcon from "./assets/eth.png";
import MaticIcon from "./assets/matic.png";
import PFANIcon from "./assets/pfan.png";
import { ethers } from "ethers";

export const network = {
  ganache: {
    slotMachineContractAddress: "0x413e2E571b77913a6D38C404F1908500014503C0",
    casinoDappTokenAddress: "0xDCD17EB4d1569C16c95D2d5339Fc8AFcbc9174F1",
    matchMakerContractAddress: "0x1F84BE29eac3d3a39b903A2C87671bfeCe797dFC",
    rpc: `https://polygon-mumbai.infura.io/v3/${process.env.INFURA_API_KEY}`,
    fromBlock: 28845865,
    nodeProviderBlockLimit: 3500,
    feeToAddToken: 0.01,
    minSpinAmount: 0.01,
    longName: "Matic (Mumbai)",
    token: {
      symbol: "MATIC",
      localIcon: MaticIcon,
    },
    metamaskChainConfig: {
      chainId: ethers.utils.hexValue(5777),
      chainName: "Matic (Mumbai)",
      nativeCurrency: {
        name: "Matic Token",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: ["https://matic-mumbai.chainstacklabs.com", "https://rpc-endpoints.superfluid.dev/mumbai"],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/", "https://explorer-mumbai.maticvigil.com/"],
    },
  },
  goerli: {
    slotMachineContractAddress: "0x36B30B38280Fa62120faCa09A1955eC478A7f101",
    casinoDappTokenAddress: "0x476DB8211205FAFf43685202c622D23C0011E8A4",
    matchMakerContractAddress: "",
    rpc: `https://goerli.infura.io/v3/${process.env.INFURA_API_KEY}`,
    fromBlock: 0,
    nodeProviderBlockLimit: 0,
    feeToAddToken: 0.01,
    minSpinAmount: 0.01,
    longName: "GoerliETH",
    token: {
      symbol: "ETH",
      localIcon: ETHIcon,
    },
    metamaskChainConfig: {
      chainId: "0x5",
    },
  },
  maticmum: {
    slotMachineContractAddress: "0x413e2E571b77913a6D38C404F1908500014503C0",
    casinoDappTokenAddress: "0xDCD17EB4d1569C16c95D2d5339Fc8AFcbc9174F1",
    matchMakerContractAddress: "0x1F84BE29eac3d3a39b903A2C87671bfeCe797dFC",
    rpc: `https://polygon-mumbai.infura.io/v3/${process.env.INFURA_API_KEY}`,
    fromBlock: 28845865,
    nodeProviderBlockLimit: 3500,
    feeToAddToken: 0.01,
    minSpinAmount: 0.01,
    longName: "Matic (Mumbai)",
    token: {
      symbol: "MATIC",
      localIcon: MaticIcon,
    },
    metamaskChainConfig: {
      chainId: "0x13881",
      chainName: "Matic (Mumbai)",
      nativeCurrency: {
        name: "Matic Token",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: ["https://matic-mumbai.chainstacklabs.com", "https://rpc-endpoints.superfluid.dev/mumbai"],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/", "https://explorer-mumbai.maticvigil.com/"],
    },
  },
  bnbt: {
    slotMachineContractAddress: "",
    casinoDappTokenAddress: "",
    matchMakerContractAddress: "",
    rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    fromBlock: 0,
    nodeProviderBlockLimit: 0,
    feeToAddToken: 0.01,
    minSpinAmount: 0.01,
    longName: "Smart Chain (Testnet)",
    token: {
      symbol: "BNB",
      localIcon: BNBIcon,
    },
    metamaskChainConfig: {
      chainId: "0x61",
      chainName: "Smart Chain (Testnet)",
      nativeCurrency: {
        name: "Binance Coin",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
      blockExplorerUrls: ["https://testnet.bscscan.com"],
    },
  },
  matic: {
    slotMachineContractAddress: "",
    casinoDappTokenAddress: "",
    matchMakerContractAddress: "",
    rpc: `https://polygon.infura.io/v3/${process.env.INFURA_API_KEY}`,
    fromBlock: 0,
    nodeProviderBlockLimit: 0,
    feeToAddToken: 0.01,
    minSpinAmount: 0.01,
    longName: "Polygon Mainnet",
    token: {
      symbol: "MATIC",
      localIcon: MaticIcon,
    },
    metamaskChainConfig: {
      chainId: "0x89",
      chainName: "Polygon Mainnet",
      nativeCurrency: {
        name: "Matic Coin",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: ["https://polygon-rpc.com/"],
      blockExplorerUrls: ["https://polygonscan.com/"],
    },
  },
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const casinoToken = {
  symbol: "PFAN",
  address: "0x476DB8211205FAFf43685202c622D23C0011E8A4", // use dummy pfan token for now
  localIcon: PFANIcon,
};
