import "./index.css";
import React, { useEffect, useState} from "react";
import { SingleEliminationBracket, Match, SVGViewer, createTheme } from '@g-loot/react-tournament-brackets';
import { mock_unstarted_tournamnet_matches } from "../../utils/mock_data";
import { useParams } from "react-router-dom";


const theme = createTheme({
  svgBackground: '#000000',
});

export default function TournamentPage() {

  const [loading, setLoading] = useState(false);

const { id, player1, player2, player3, player4, player5, player6, player7, player8 } = useParams();


  useEffect(() => {
    

    console.log("player1: ", player1);
    console.log("player1: ", player2);
    console.log("player1: ", player3);
    console.log("player1: ", player4);

    if(player1 && player2 && player3 && player4 && player5 && player6&& player7&& player8) {

      mock_unstarted_tournamnet_matches[0].participants[0].name = player3;
      mock_unstarted_tournamnet_matches[0].participants[1].name = player2;

      mock_unstarted_tournamnet_matches[1].participants[0].name = player1;
      mock_unstarted_tournamnet_matches[1].participants[1].name = player4;

      mock_unstarted_tournamnet_matches[2].participants[0].name = player5;
      mock_unstarted_tournamnet_matches[2].participants[1].name = player6;
      
      mock_unstarted_tournamnet_matches[3].participants[0].name = player8;
      mock_unstarted_tournamnet_matches[3].participants[1].name = player7;
      setLoading(false);
      console.log("loading: ", loading);
    }

  },[loading]);


  return (
    
    <div className='tournament_page'>
      <SingleEliminationBracket
        matches={mock_unstarted_tournamnet_matches}
        matchComponent={Match}
        theme={theme}
        options={{
          style: {
            svgBackground: '#000000',
            background: '#000000'
          }
        }}
        svgWrapper={({ children, ...props }) => (
          <SVGViewer width={2000} height={2000} {...props}
            SVGBackground={"#000000"} background={"#000000"}>
            {children}
          </SVGViewer>
        )}
      />
    </div>
  )
}
