import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./index.css";

const items = [
  {
    title: "How to play Solair?",
    bodytext:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vestibulum pharetra, felis ultricies tortor.",
    videoLink: "https://www.youtube.com/embed/wuOPHY2tOOM",
  },
  {
    title: "What is Network?",
    bodytext:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vestibulum pharetra, felis ultricies tortor.",
    videoLink: "https://www.youtube.com/embed/wuOPHY2tOOM",
  },
  {
    title: "How to add token?",
    bodytext:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vestibulum pharetra, felis ultricies tortor.",
    videoLink: "https://www.youtube.com/embed/wuOPHY2tOOM",
  },
  {
    title: "How blockchain works?",
    bodytext:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vestibulum pharetra, felis ultricies tortor.",
    videoLink: "https://www.youtube.com/embed/wuOPHY2tOOM",
  },
  {
    title: "How to connect wallet?",
    bodytext:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vestibulum pharetra, felis ultricies tortor.",
    videoLink: "https://www.youtube.com/embed/wuOPHY2tOOM",
  },
];

export default function Tutorials() {
  return (
    <div>
      <div className="tutorial_main_heading">Tutorials</div>
      <div className="tutorial_main_title">Some big title goes here</div>
      <Accordion defaultActiveKey="0">
        {items.map((item, i) => (
          <Accordion.Item eventKey={i.toString()}>
            <Accordion.Header className="accordion_title">{item.title}</Accordion.Header>
            <Accordion.Body>
              <div className="accordion_body_text">{item.bodytext}</div>
              <iframe
                className="accordion_body_video"
                width="350"
                height="265"
                src={item.videoLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}

// export default BasicExample;
