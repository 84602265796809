import React, { useContext, useEffect, useState } from "react";
import { Web3Context } from "../contexts/Web3.context";
import { getRollFulfilledEvents } from "../services/events";

function useEvents({ winType, limit }) {
  const { config, accountAddress, jsonRpcProvider, provider } = useContext(Web3Context);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function getEventsFromContractRpc() {
      if (config?.slotMachineContractRpc) {
        const currentBlock = await jsonRpcProvider.getBlockNumber();
        const fromBlock = currentBlock - config.nodeProviderBlockLimit + 2;

        const events = await getRollFulfilledEvents({
          contract: config.slotMachineContractRpc,
          provider,
          fromBlock: fromBlock,
          blockLimit: "latest",
          limit: 2,
          winType,
        });
        setEvents(events ? events : []);
      }
    }
    getEventsFromContractRpc();
    () => setEvents([]);
  }, [config, accountAddress]);

  return events;
}

export default useEvents;
