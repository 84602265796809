import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./locales/en.json";
import tl from "./locales/tl.json";
import ch from "./locales/ch.json";
import es from "./locales/es.json";
import ko from "./locales/ko.json";

const config = {
    fallbackLng: 'en',
    debug: true,
    lng: "en",
    resources: {
        en: { ...en },
        tl: { ...tl },
        ch: { ...ch },
        ko: { ...ko },
        es: { ...es }
    },
    ns: ["main_page", "navbar", "referral_history", "earnings_page",
     "referral_page", "apply_token_page" ,"settings_page", "tutorial_page"]
};

console.log(config);

i18n
    .use(initReactI18next)
    .init(config);


export default i18n;