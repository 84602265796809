import { ethers } from "ethers";
import React, { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Web3Context } from "../../contexts/Web3.context";
import { network as networkConfig } from "../../config";

function ApplyToken() {
  const { network, config } = useContext(Web3Context);
  const [token, setToken] = useState("");
  const [price, setPrice] = useState(0);
  const { t } = useTranslation("apply_token_page");

  const handleApplyToken = async () => {
    if (!ethers.utils.isAddress(token)) {
      alert("Invalid token address");
      return false;
    }

    if (price <= 0) {
      alert("Price should not less than to zero");
      return false;
    }

    try {
      const tx = await config.slotMachineContractWSigner.applyToAddToken(token, ethers.utils.parseEther(price), {
        value: ethers.utils.parseEther(String(config.feeToAddToken)),
      });

      await tx.wait();

      setToken("");
      setPrice(0);

      alert("Succesfully applied your token");
    } catch (error) {
      alert(error?.message || "Error on applying token");
    }
  };

  return (
    <div className="casino__apply">
      <div className="dropdown mb-3" style={{ width: "90%" }}>
        <div className="text-white mb-2">{t("blockchain")}</div>
        <button
          className="btn dropdown-toggle rounded-pill px-4 mx-auto text-white w-100 d-flex flex-row justify-content-between align-items-center"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ backgroundColor: "#E41717", border: "2px solid rgba(0, 0, 0, 0.4)", height: 48 }}>
          {networkConfig[network]?.longName}
        </button>
        <ul className="dropdown-menu"></ul>
      </div>

      <div style={{ width: "90%" }}>
        <div className="text-white mb-2">{t("input_token_address")}</div>
        <div className="input-group mb-3 w-100">
          <input
            value={token}
            type="text"
            className="form-control"
            placeholder="Token Address"
            aria-label="Token Address"
            aria-describedby="basic-addon1"
            onChange={e => setToken(e.target.value)}
          />
        </div>
      </div>

      <div style={{ width: "90%" }}>
        <div className="text-white mb-2">{t("coin_price")}</div>
        <div className="input-group mb-5 w-100">
          <input
            value={price}
            type="number"
            className="form-control"
            placeholder="Coin Price"
            aria-label="Coin Price"
            aria-describedby="basic-addon1"
            onChange={e => setPrice(e.target.value)}
          />
        </div>
      </div>

      <button
        onClick={handleApplyToken}
        className="btn rounded-pill text-white"
        style={{ backgroundColor: "#E41717", width: "90%", height: 48, border: "2px solid white" }}>
        {t("apply_token")}
      </button>
    </div>
  );
}

export default ApplyToken;
