import React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";

import { AppContextProvider } from "./contexts";

// Components
import Main from "./containers/Main";
import Navbar from "./components/Navbar";
import RewardHistory from "./containers/RewardHistory";
import ApplyToken from "./containers/ApplyToken";
import Referrals from "./containers/Referrals";
import Tutorials from "./containers/Tutorials";
import Settings from "./containers/Settings";
import Login from "./containers/Login";
import HomePage from "./containers/HomePage";
import MyTournaments from "./containers/MyTournaments";
import NetworkWarning from "./components/NetworkWarning";
import Loader from "./components/Loader";
import TournamentPage from "./containers/Tournament";
import UserProfilePage from "./containers/UserProfile/UserProfilePage";

function App() {
  return (
    <AppContextProvider>
      <div className="App">
        <Loader />
        <HashRouter>
          <div>
            <Navbar />
            {/* <NetworkWarning /> */}
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/tournament/:id" element={<TournamentPage />} />
              <Route path="/tournament/:id/:player1/:player2/:player3/:player4/:player5/:player6/:player7/:player8" element={<TournamentPage />} />
              <Route path="/profile" element={<UserProfilePage />} />
              <Route path="/my_tournaments" element={<MyTournaments />} />
              <Route path="/history" element={<RewardHistory />} />
              <Route path="/apply" element={<ApplyToken />} />
              <Route path="/referrals" element={<Referrals />} />
              <Route path="/:address" element={<Main />} />
              <Route path="/tutorials" element={<Tutorials />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </div>
        </HashRouter>
      </div>
    </AppContextProvider>
  );
}

export default App;
