import React from 'react'
import { mock_tournamnets } from '../../utils/mock_data';
import "./index.css";

export default function Index() {

    const [selectedTab, setSelectedTab] = React.useState(1);

    return (
        <div className='my_tournaments_main'>
            <div className='tabs'>
                <div className={'tab ' + `${selectedTab === 1 ? "selected-tab" : ""}`} onClick={() => { setSelectedTab(1) }}>
                    Hosted
                </div>
                <div className={'tab ' + `${selectedTab === 2 ? "selected-tab" : ""}`} onClick={() => { setSelectedTab(2) }}>
                    Participated
                </div>
            </div>
            <div className='content'>
                {selectedTab === 1 ?
                    <div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Id</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Players</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Winner</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    mock_tournamnets.map((tournament, i) => (
                                        <tr>
                                            <th scope="row">{i + 1}</th>
                                            <td>{tournament.id}</td>
                                            <td>{tournament.name}</td>
                                            <td>{tournament.players}</td>
                                            <td>{tournament.status}</td>
                                            <td>{tournament.winner}</td>
                                            <td>
                                                <button className="btn btn-primary">Join</button>
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div> :
                    <div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Id</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Players</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Winner</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    mock_tournamnets.map((tournament, i) => (
                                        <tr>
                                            <th scope="row">{i + 1}</th>
                                            <td>{tournament.id}</td>
                                            <td>{tournament.name}</td>
                                            <td>{tournament.players}</td>
                                            <td>{tournament.status}</td>
                                            <td>{tournament.winner}</td>
                                            <td>
                                                <button className="btn btn-primary">Join</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    )
}
