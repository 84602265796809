import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ethers } from "ethers";
import moment from "moment/moment";
import { Web3Context } from "../../contexts/Web3.context";
import usePastWinners from "../../hooks/usePastWinners";
import SlotMachine from "jquery-slotmachine/lib/slot-machine.js";
import { useTranslation } from "react-i18next";
import Machine from "../../components/SlotMachine";
import { ZERO_ADDRESS } from "../../config";
import { SlotMachineContext } from "../../contexts/SlotMachine.context";
import { erc20Abi } from "../../utils/commonAbis";
import BackgroundSound from "../../assets/short-background-casino.mp3";
import { getSlicedWalletAddress } from "../../utils/helpers";
import "./index.css";
// https://stackoverflow.com/questions/38252453/serving-mp3-files-using-the-webpack-file-loader
// import WinSound from "../assets/winning-sound-effect.mp3";

let requestIdTimeout = null;

const Main = () => {
  // Props
  const referrerAsPathname = String(useLocation()?.pathname).substring(1);

  // Hooks
  const pastWinners = usePastWinners({
    winType: [
      ethers.utils.formatBytes32String("jackpot"),
      ethers.utils.formatBytes32String("ultrawin"),
      ethers.utils.formatBytes32String("winning"),
    ],
  });

  // Contexts
  const { config, signer, provider } = useContext(Web3Context);
  const { selectedToken, defaultToken, getTotalEarningsThenUpdateTokens } = useContext(SlotMachineContext);
  const { t } = useTranslation("main_page");

  // Refs
  const buttonRef = useRef(null);

  // States
  const [machine1, setMachine1] = useState(null);
  const [machine2, setMachine2] = useState(null);
  const [machine3, setMachine3] = useState(null);
  const [rolls, setRolls] = useState([1, 2, 3]);
  const [rollsFetched, setRollsFetched] = useState(false);
  const [rollStarted, setRollStarted] = useState(false);
  const [reward, setReward] = useState(0);
  const [winType, setWinType] = useState("");
  const [approved, setApproved] = useState(false);
  const [useWinningsAsGas, setUseWinningsAsGas] = useState(false);

  useEffect(() => {
    const door1 = new SlotMachine(document.querySelector("#door1"), {
      active: 0,
      delay: 2000,
      randomize: () => {
        return rolls[0];
      },
    });
    const door2 = new SlotMachine(document.querySelector("#door2"), {
      active: 1,
      delay: 2000,
      randomize: () => {
        return rolls[1];
      },
    });
    const door3 = new SlotMachine(document.querySelector("#door3"), {
      active: 2,
      delay: 2000,
      randomize: () => {
        return rolls[2];
      },
    });

    setMachine1(door1);
    setMachine2(door2);
    setMachine3(door3);

    const backgroundSoundSetting = localStorage.getItem("backgroundSoundSetting");
    if (!backgroundSoundSetting || backgroundSoundSetting === "true") {
      const audio = new Audio(BackgroundSound);
      const playBackgroundSound = () => {
        audio.play();
        document.body.removeEventListener("click", playBackgroundSound, true);
      };
      // can't play auto in background  https://developer.chrome.com/blog/autoplay/
      document.body.addEventListener("click", playBackgroundSound, true);
      // when component unmounts
      return () => {
        audio.pause();
        document.body.removeEventListener("click", playBackgroundSound, true);
      };
    }
  }, []);

  useEffect(() => {
    async function checkRequestId() {
      if (localStorage.getItem("requestId")) {
        const requestId = localStorage.getItem("requestId");
        requestIdTimeout = setInterval(function () {
          rollFulfilledListener(requestId);
        }, 2000);
      }
    }
    checkRequestId();
    return () => {};
  }, [config?.slotMachineContract]);

  const rollFulfilledListener = async requestId => {
    if (config?.slotMachineContract) {
      const vrfRequest = await config.slotMachineContract.vrfRequestsMap(requestId);
      const playersAddress = parseInt(vrfRequest.playersAddress, 16);

      if (playersAddress !== 0) {
        setRollStarted(true);

        const rolls = [
          parseInt(String(vrfRequest.rand1)) - 1,
          parseInt(String(vrfRequest.rand2)) - 1,
          parseInt(String(vrfRequest.rand3)) - 1,
        ];

        machine1.shuffle(9999);
        machine2.shuffle(9999);
        machine3.shuffle(9999);

        if (rolls.join() !== "-1,-1,-1") {
          const reward = ethers.utils.formatEther(String(vrfRequest.reward));
          const winType = ethers.utils.parseBytes32String(String(vrfRequest.winType));

          console.log("Random numbers: ", rolls);
          console.log("Reward: ", reward);
          console.log("Win type: ", winType);

          setRolls(rolls);
          setReward(reward);
          setWinType(winType);
          setRollsFetched(true);

          if (localStorage.getItem("requestId")) {
            localStorage.removeItem("requestId");
          }
          clearInterval(requestIdTimeout);
        }
      }
    }
  };

  const handleApprove = async () => {
    disableButtonManual();
    const contract = new ethers.Contract(selectedToken.tokenAddress, erc20Abi, provider);
    const approveTx = await contract
      .connect(signer)
      .approve(config.slotMachineContractAddress, selectedToken.oneRollPrice);

    await approveTx.wait();
    setApproved(true);
    disableButtonManual(false);
  };

  const handleSpin = async () => {
    const referrer = referrerAsPathname || ZERO_ADDRESS;

    setRollStarted(true);

    machine1.shuffle(9999);
    machine2.shuffle(9999);
    machine3.shuffle(9999);

    if (!config?.slotMachineContractWSigner) {
      throw new Error("Slot machine contract is undefined");
    }

    let tx;
    let gasEstimated = 1206788;

    if (selectedToken?.tokenAddress === "0x") {
      gasEstimated = await config.slotMachineContractWSigner.estimateGas.oneRoll(referrer, {
        value: useWinningsAsGas ? 0 : selectedToken.oneRollPrice,
      });

      tx = await config.slotMachineContractWSigner.oneRoll(referrer, {
        value: useWinningsAsGas ? 0 : selectedToken.oneRollPrice,
        gasLimit: gasEstimated,
      });
    } else {
      gasEstimated = await config.slotMachineContractWSigner.estimateGas.oneRollERC20Token(
        referrer,
        selectedToken.tokenAddress,
        selectedToken.oneRollPrice,
        {
          gasLimit: gasEstimated,
        },
      );

      tx = await config.slotMachineContractWSigner.oneRollERC20Token(
        referrer,
        selectedToken.tokenAddress,
        selectedToken.oneRollPrice,
        {
          gasLimit: gasEstimated,
        },
      );
    }

    tx = await tx.wait();
    tx = tx.events.filter(x => x.event === "RequestRandomness")[0].args;
    localStorage.setItem("requestId", tx.requestId);
    requestIdTimeout = setInterval(function () {
      rollFulfilledListener(tx.requestId);
    }, 2000); // 2 seconds
  };

  const handleStop = () => {
    if (rollsFetched) {
      if (machine1.running) {
        machine1.randomize = () => {
          return rolls[0];
        };
        machine1.stop();
      } else if (machine2.running) {
        machine2.randomize = () => {
          return rolls[1];
        };
        machine2.stop();
      } else {
        machine3.randomize = () => {
          return rolls[2];
        };
        machine3.stop(() => {
          setRollStarted(false);
          getTotalEarningsThenUpdateTokens();
        });
      }
    } else {
      alert("Fetching results from the blockchain...");
    }
  };

  const disableButtonManual = (disabled = true) => {
    buttonRef.current.disabled = disabled;
    if (disabled) {
      buttonRef.current.style.opacity = 0.7;
    } else {
      buttonRef.current.style.opacity = 1;
    }
  };

  const handleReset = () => {
    setRolls([1, 2, 3]);
    setRollsFetched(false);
    setRollStarted(false);
    setReward(0);
    setWinType("");
    setApproved(false);
  };

  const renderButton = () => {
    if (!rollStarted && !rollsFetched && selectedToken.tokenAddress === "0x") {
      return (
        <button ref={buttonRef} id="spinner" className="main-btn" onClick={handleSpin}>
          {t("spin")}
        </button>
      );
    }

    if (!rollStarted && !rollsFetched && selectedToken.tokenAddress !== "0x") {
      return approved ? (
        <button ref={buttonRef} id="token-spinner" className="main-btn" onClick={handleSpin}>
          {t("spin_token")}
        </button>
      ) : (
        <button ref={buttonRef} id="approve" className="main-btn" onClick={handleApprove}>
          {t("approve")}
        </button>
      );
    }

    if (rollStarted && rollsFetched) {
      return (
        <button ref={buttonRef} id="stopper" className="main-btn" onClick={handleStop}>
          {t("stop")}
        </button>
      );
    }

    if (rollStarted && !rollsFetched) {
      return (
        <button ref={buttonRef} className="main-btn" disabled style={{ opacity: 0.7 }}>
          {t("wait")}...
        </button>
      );
    }

    if (!rollStarted && rollsFetched) {
      return (
        <button ref={buttonRef} id="resetter" className="main-btn" onClick={handleReset}>
          {t("try_again")}
        </button>
      );
    }
  };

  return (
    <>
      <div className="casino__background"></div>
      <div className="casino__main">
        <div className="align-self-start mt-below-navbar mb-3 ms-3 d-flex flex-row align-items-center">
          {selectedToken.tokenAddress === "0x" && (
            <>
              <label className="gas-switch">
                <input
                  type="checkbox"
                  onChange={() => setUseWinningsAsGas(prevState => !prevState)}
                  checked={useWinningsAsGas}
                />
                <span className="slider round"></span>
              </label>
              <span className="text-white ms-2" style={{ fontSize: "0.9rem" }}>
                Play with winnings
              </span>
            </>
          )}
        </div>

        <div className="total-earned">
          {t("total_earned")}:{" "}
          {selectedToken?.totalEarnings ? ethers.utils.formatEther(String(selectedToken.totalEarnings)) : 0}{" "}
          {selectedToken.symbol}
        </div>
        <Machine
          reward={reward}
          rollStarted={rollStarted}
          rollsFetched={rollsFetched}
          winType={winType}
          tokenPrice={selectedToken?.oneRollPrice}
        />
        <div className="casino__buttons w-100">{renderButton()}</div>

        {pastWinners.length > 0 && (
          <div className="mt-5 mb-5" style={{ width: "90%" }}>
            {pastWinners.map(pastWinner => (
              <div
                key={`past-winner-${pastWinner.requestId}`}
                className="card px-3 py-2 mb-2 d-flex flex-row align-items-center justify-content-between">
                <a href="#" className="text-decoration-none" style={{ fontSize: "0.8rem" }}>
                  {getSlicedWalletAddress(pastWinner.sender)} {"won"}{" "}
                  {ethers.utils.formatEther(String(pastWinner.vrfRequest.reward))}{" "}
                  {pastWinner?.symbol !== "CDAPP" ? pastWinner.symbol : defaultToken.symbol}
                </a>
                <span className="text-secondary" style={{ fontSize: "0.75rem" }}>
                  {moment(pastWinner?.timestamp * 1000).fromNow()}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Main;
