import React, { createContext, useState, useEffect } from "react";
import { casinoToken, network as networkConfig } from "../config";
import { ethers } from "ethers";
import SlotMachine from "../abis/SlotMachine.json";
import { erc20Abi } from "../utils/commonAbis";
import PlaceHolderToken from "../assets/placeholder-token.png";

export const SlotMachineContext = createContext(null);

const initialState = {
  symbol: "",
  isAdded: "",
  isBanned: "",
  oneRollPrice: 0,
  tokenAddress: "",
  totalEarnings: 0,
  localIcon: "",
};

export const SlotMachineContextProvider = ({ children }) => {
  const [defaultToken, setDefaultToken] = useState(initialState);
  const [selectedToken, setSelectedToken] = useState(initialState);
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.ethereum) {
      getTokensWithEarnings();
      window.ethereum.on("chainChanged", () => {
        getTokensWithEarnings();
      });
      window.ethereum.on("accountsChanged", () => {
        getTokensWithEarnings();
      });
    }
    return () => {
      setSelectedToken(initialState);
    };
  }, []);

  const getTokensWithEarnings = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const { name } = await provider.getNetwork();
      const signer = provider.getSigner();
      const account = await signer.getAddress();

      if (!networkConfig[name]?.slotMachineContractAddress) {
        throw new Error(`No contract address initialized for ${name}`);
      }

      const slotMachineContract = new ethers.Contract(
        networkConfig[name].slotMachineContractAddress,
        SlotMachine.abi,
        provider,
      );

      setLoading(true);

      const [gasTokenTotalEarnings, gasTokenPrice] = await Promise.all([
        slotMachineContract.getPendingWithdrawals(account),
        slotMachineContract.coinPrice(),
      ]);

      const gasToken = {
        ...initialState,
        symbol: networkConfig[name]?.token?.symbol,
        localIcon: networkConfig[name]?.token?.localIcon,
        oneRollPrice: gasTokenPrice,
        tokenAddress: "0x",
        totalEarnings: gasTokenTotalEarnings,
      };

      const resTokens = await slotMachineContract.getAllTokens();

      const formatTokens = await Promise.all(
        resTokens.map(async token => {
          const erc20Contract = new ethers.Contract(token.tokenAddress, erc20Abi, provider);

          const [totalEarnings, symbol] = await Promise.all([
            slotMachineContract.getPendingERC20Withdrawals(account, token?.tokenAddress),
            erc20Contract.symbol(),
          ]);

          return {
            ...initialState,
            symbol,
            isAdded: token?.isAdded,
            isBanned: token?.isBanned,
            oneRollPrice: token?.oneRollPrice,
            tokenAddress: token?.tokenAddress,
            totalEarnings,
            localIcon: PlaceHolderToken,
          };
        }),
      );

      setLoading(false);

      const pfanToken = {
        ...initialState,
        symbol: casinoToken.symbol,
        localIcon: casinoToken.localIcon,
        tokenAddress: casinoToken.address,
      };

      setTokens([gasToken, pfanToken, ...formatTokens]);
      setSelectedToken(gasToken);
      setDefaultToken(gasToken);
    } catch (error) {
      console.log(error);
    }
  };

  const getTokenByAddress = address => {
    if (tokens.length > 0) {
      const token = tokens.find(item => String(item.tokenAddress).toUpperCase() === String(address).toUpperCase());
      setSelectedToken(token);
    }
  };

  const getTotalEarningsThenUpdateTokens = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const { name } = await provider.getNetwork();
    const signer = provider.getSigner();
    const account = await signer.getAddress();

    if (!networkConfig[name]?.slotMachineContractAddress) {
      throw new Error(`No contract address initialized for ${name}`);
    }

    const slotMachineContract = new ethers.Contract(
      networkConfig[name].slotMachineContractAddress,
      SlotMachine.abi,
      provider,
    );

    let newTotalEarnings = 0;
    if (selectedToken?.tokenAddress !== "0x") {
      newTotalEarnings = await slotMachineContract.getPendingERC20Withdrawals(account, selectedToken?.tokenAddress);
    } else {
      newTotalEarnings = await slotMachineContract.getPendingWithdrawals(account);
    }

    const idx = tokens.findIndex(
      item => String(item.tokenAddress).toUpperCase() === String(selectedToken?.tokenAddress).toUpperCase(),
    );

    if (idx !== -1) {
      const temp = [...tokens];
      temp[idx].totalEarnings = newTotalEarnings;
      setTokens(temp);
    }
  };

  return (
    <SlotMachineContext.Provider
      value={{ defaultToken, selectedToken, tokens, getTokenByAddress, loading, getTotalEarningsThenUpdateTokens }}>
      {children}
    </SlotMachineContext.Provider>
  );
};
